import useUserInfo from "@hooks/useUserInfo/useUserInfo";
import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { CMSPage } from "@jwm/types/CMSPage";
import { useLocation } from "@reach/router";
import { ApplicationState } from "@redux/modules";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sizeMe from "react-sizeme";

import { set as setPageSpecialty } from "../../redux/modules/pageSpecialty";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    specialty?: {
        title: string;
        type: string;
        emea_specialty: string;
    };
    size?: { height: number };
    context?: {
        page?: CMSPage;
    };
}

export const PageImpression = (props: PropTypes): JSX.Element => {
    const { children, size, specialty, context } = props;
    const [trackImpression, setTrackImpression] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);

    const locale = useLocale();
    const location = useLocation();
    const dispatch = useDispatch();
    const { interacted } = useCookieInteraction();
    const pageSpecialty = useSelector((state: ApplicationState) => state?.pageSpecialty?.title);
    const [loginType, setLoginType] = useState("unset");
    const [loginId, setLoginId] = useState("unset");
    const userInfo = useUserInfo();

    // function to allow tracking of the page impression
    const allowTracking = (): void => {
        setTimeout(() => {
            setTrackImpression(true);
        }, 500); // the 500ms makes sure we have the proper height
    };

    useEffect(() => {
        if (userInfo.isAuthenticated()) {
            setLoginId("Patient_ID");
            setLoginType(userInfo.getProvider().loginType);
        } else {
            if (userInfo.isValidAccessCodeForPage(location?.pathname?.replace("/" + locale, ""))) {
                setLoginId("anonymous");
                setLoginType("accesscode");
            }
        }

        setProfileLoaded(true);
    }, [userInfo]);

    // only track a page impression when the path has changed
    const [previousLocation, setPreviousLocation] = React.useState(null);
    useEffect(() => {
        if (!previousLocation || location.pathname !== previousLocation.pathname) {
            allowTracking();
        }
        setPreviousLocation(location);
    }, [location]);

    // clear tracking after tracking the page impression
    useEffect(() => {
        if (trackImpression && profileLoaded && interacted) {
            // if specialty is not published but linked to a mini-website
            const unPublishedSpecialty = !context?.page?.site_reference?.[0]?.related_to?.length && !specialty;
            const specialtyTitle = unPublishedSpecialty ? "" : specialty?.title;
            if (specialtyTitle !== null && typeof specialtyTitle !== "undefined") {
                analyticstracker().trackImpression(EventTypes.PAGE_IMPRESSION, { newPage: true });
                setTrackImpression(false);
            } else {
                setTrackImpression(false);
                allowTracking();
            }
        }
    }, [trackImpression, profileLoaded, interacted]);

    useEffect(() => {
        if (pageSpecialty !== null) {
            specialty.title = pageSpecialty;
        }

        return () => {
            dispatch(setPageSpecialty({ title: null }));
        };
    }, [pageSpecialty]);

    const siteReference = context?.page?.site_reference?.[0];
    const siteMaintenance = siteReference?.maintenance_mode?.temporary_maintenance_mode;
    const pageMaintenance = context?.page?.maintenance_mode?.temporary_maintenance_mode;

    const trackingInfo = {
        locale: (locale || "").toUpperCase(),
        totalpixelheight: Math.round(size?.height || 0),
        specialtytype: specialty?.type || "",
        specialty: specialty?.title || "",
        emea_specialty: specialty?.emea_specialty || "unset",
        accesslevel: context?.page?.access_control?.access_level,
        traffic_type: "internal",
        site_type: "unset",
        site_name: "unset",
        service: context?.page?.service_name || "unset",
        declaration: !!siteReference?.patient_declaration?.[0]?.declaration,
        user: {
            loginid: loginId,
            loginType: loginType,
        },
    };

    if (siteMaintenance || pageMaintenance) trackingInfo.title = "Page under maintenance";
    if (context?.uid === "404") trackingInfo.title = "404";
    if (siteReference?.site_menu?.length) trackingInfo.site_name = "minisite";
    if (siteReference?.standalone) {
        trackingInfo.site_name = "unset";
        trackingInfo.site_type = "standalone";
    }
    if (process.env.GATSBY_ENVIRONMENT === "prod") trackingInfo.traffic_type = "unset";

    return (
        <div
            role="none"
            data-tracking-event={EventTypes.PAGE_IMPRESSION}
            data-tracking-info={JSON.stringify(trackingInfo)}
        >
            {children}
        </div>
    );
};

export default sizeMe({ monitorWidth: false, monitorHeight: true })(PageImpression);
